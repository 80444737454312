import { restful } from '../utils/restful'

//余额支付
export function balancePayHttp(par = {}) {
  var url = '/api/pay/balancePay'
  return restful(par, url, true)
}

//第三方充值到余额 （生成随机码和支付地址）
export function recPayHttp(par = {}) {
  var url = '/api/pay/recPay'
  return restful(par, url, true)
}

//查询支付状态和刷新支付码 用于五秒刷新 
export function queryPayStatusHttp(par = {}) {
  var url = '/api/pay/queryPayStatus'
  return restful(par, url, true)
}

//用户提现
export function cashAdvanceHttp(par = {}) {
  var url = '/api/pay/cashAdvance'
  return restful(par, url, true)
}

//账单【filterType】：0 所有 1 消费记录 2 收入记录 3 提现记录 4 充值记录
export function billHttp(par = {}) {
  var url = '/api/pay/bill'
  return restful(par, url, true)
}

//支付方式显示，deviceId：  0 pc 1 h5 2 公众号，  [0,1,2]  余额 ，阿里，微信
export function payMethodShowHttp(par = {}) {
  var url = '/api/pay/payMethodShow'
  return restful(par, url, true)
}

//支付显示接口
export function payshow(par = {}) {
  var url = '/api/comm/buttonSet'
  return restful(par, url, true)
}

// 包赔数据
export function getBaoPeiList(par = {}) {
  var url = '/api/goods/distEnsureShowList'
  return restful(par, url, true)
}

//砍价系统支付接口
export function bargainPayHttp(par = {}) {
  var url = '/api/order/payBargainOrder'
  return restful(par, url, true)
}


export function getCompensationPrice(par = {}) {//获取包赔价格
  var url = '/api/order/getCalculateInsurancePrice'
  return restful(par, url, true)
}



export function addrecord(par = {}) {//获取包赔价格
  var url = '/api/order/createPayForInsuranceFn'
  return restful(par, url, true)
}

export function paymentoney(par = {}) {//包赔支付接口
  var url = '/api/pay/addInsureRecPayFn'
  return restful(par, url, true)
}



export function getAddInsureDetail(par = {}) {//包赔支付判断是否成功
  var url = '/api/order/getAddInsureDetail'
  return restful(par, url, true)
}

export function isLianlianuser(par = {}) {//判断用户是否已经开户
  var url = '/api/lianlianpay/checkUserHaveLianlian'
  return restful(par, url, true)
}

export function Lianlianlist(par = {}) {//连连提现列表
  var url = '/api/withdrawLogs/list'
  return restful(par, url, true)
}


export function LianlianCancel(par = {}) {//连连提现取消
  var url = '/api/withdrawLogs/update'
  return restful(par, url, true)
}



export function createPartialPaymentRecordFn(par = {}) {//创建分批购买订单
  var url = '/api/order/createPartialPaymentRecordFn'
  return restful(par, url, true)
}


export function partialPaymentRecPayFn(par = {}) {//创建分批购买订单
  var url = '/api/pay/partialPaymentRecPayFn'
  return restful(par, url, true)
}


export function getPartialPaymentRecordDetail(par = {}) {//分批购买订单状态
  var url = '/api/order/getPartialPaymentRecordDetail'
  return restful(par, url, true)
}

export function changeRegPhone(par = {}) {//修改注册手机号
  var url = '/api/lianlianpay/changeRegPhone'
  return restful(par, url, true)
}

export function changeCardApply(par = {}) {//修改绑定银行卡
  var url = '/api/lianlianpay/changeCardApply'
  return restful(par, url, true)
}
export function changeCardVerify(par = {}) {//修改绑定银行卡验证
  var url = '/api/lianlianpay/changeCardVerify'
  return restful(par, url, true)
}

export function changeRegPhoneVerify(par = {}) {//修改注册手机号验证
  var url = '/api/lianlianpay/changeRegPhoneVerify'
  return restful(par, url, true)
}

export function changeLinkedPhone(par = {}) {//修改预留手机号
  var url = '/api/lianlianpay/changeLinkedPhone'
  return restful(par, url, true)
}

export function changeLinkedPhoneVerify(par = {}) {//修改预留手机号验证
  var url = '/api/lianlianpay/changeLinkedPhoneVerify'
  return restful(par, url, true)
}

export function regPhone(par = {}) {//绑定开户手机号
  var url = '/api/lianlianpay/regPhone'
  return restful(par, url, true)
}

export function regPhoneVerify(par = {}) {//绑定开户手机号
  var url = '/api/lianlianpay/regPhoneVerify'
  return restful(par, url, true)
}

export function openAccount(par = {}) {//开户
  var url = '/api/lianlianpay/openAccount'
  return restful(par, url, true)
}
export function openAccountVerify(par = {}) {//开户验证
  var url = '/api/lianlianpay/openAccountVerify'
  return restful(par, url, true)
}
export function syncLianlianAccount(par = {}) {//资金管理信息
  var url = '/api/user/syncLianlianAccount'
  return restful(par, url, true)
}

export function cancelAccount(par = {}) {//取消开户
  var url = '/api/lianlianpay/cancelAccount'
  return restful(par, url, true)
}